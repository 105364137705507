<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from 'moment'

export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ]
      },
      formItemList: [
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "申请时间",
          placeholder: "请选择创建时间",
          valueFormat: "timestamp",
          clearable: true,
        },
        {
          key: "merchantName",
          type: "input",
          labelName: "商家名称",
          placeholder: "请输入商家名称",
        },
        {
          key: "phone",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        {
          key: 'isEnable',
          type: 'select',
          lableWidth:'100',
          labelName: '物流开启状态',
          option: [
            {
              value: '',
              label: '全部',
            },
            {
              value: '01',
              label: '启用',
            },
            {
              value: '00',
              label: '禁用',
            },
         
          ],
        },
      ],
    };
  },
  created() {
    this.handleConfirm(this.initData)
  },
  watch:{
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (data) {
        SeachParams = {
          merchantName: data.merchantName,
          phone: data.phone,
          isEnable: data.isEnable,
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
            if (typeof cd === 'function') {
              cd()
            }
          },
        };
      }

      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
