import {pageSize} from "@/utils/common";

// 物流申请列表和添加合作商家
export const applyForProps = {
    show:false,
    isResetForm:false,
    title:"创建物流申请",
    width: "53%",
    loading:true,
    isTabSelection:false,
    selectable:(v)=>true,
    params:{
        pageSize,
        pageNum:1,
        merchantName:""
    },
    formItemList:[
        {
            labelName: "商家名称",
            key: 'merchantName',
            lableWidth:"70px",
            type: "input",
            placeholder: "请输入商家名称查询",
        },
    ],
    tableColumns:[
        {
            prop: "select",
            label: "单选",
            width: "60px"
        },
        {
            prop: "merchantName",
            label: "商家名称",
        },
        {
            prop: "contactName",
            label: "联系电话"
        },
        {
            prop: "contactPhone",
            label: "电话"
        },
        {
            prop: "merchantAddress",
            label: "地址"
        },
        {
            prop: "entryTime",
            label:"入驻时间"
        }
    ],
    tabData:[],
    // 当前选择项
    selectId:"",
    // 当前行数据
    currentData:{},
    // 是否请求物流申请列，true是，false代表请求合作商家
    isApplyFor:true,
    // 所有选择的数据
    allSelectData:[],
    // 分页选择的数据
    selectData:[],
    isSearch:true,
    listKey:"companyId"
}

export const applyForTableColumns = [
    {
        prop: "select",
        label: "单选",
        width: "60px"
    },
    {
        prop: "merchantName",
        label: "商家名称",
    },
    {
        prop: "contactName",
        label: "联系电话"
    },
    {
        prop: "contactPhone",
        label: "电话"
    },
    {
        prop: "merchantAddress",
        label: "地址"
    },
    {
        prop: "entryTime",
        label:"入驻时间"
    }
]

// 物流申请列列表
export const cooperaTableColumns = [
    {
        prop: "companyName",
        label: "商家名称",
    },
    {
        prop: "contact",
        label: "联系人"
    },
    {
        prop: "phone",
        label: "电话"
    },
    {
        prop: "address",
        label: "地址"
    },
    {
        prop: "createTime",
        label:"入驻时间"
    }
]


//启用或者禁用物流弹窗
export const logisticsSwitchProps = {
    show:false,
    title:"启用提示",
    isTip:true,
    tipText:"",
    headerMessage:"",
    isCode:false,
    currentData:{},
    code:""
}
